import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { CloseSharp } from '@material-ui/icons'
import { CircularProgress, makeStyles, Button, Dialog, Slider } from '@material-ui/core'
import { DeleteSharp, CloseOutlined } from '@material-ui/icons';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../utils/imageCrop'; // Import the cropImage utility

const useStyles = makeStyles((theme) => ({
   root: {
      padding: 20,
      maxWidth: 300,
      textAlign: 'center',
      backgroundColor: '#f5f5f5',
      borderRadius: theme.shape.borderRadius,
   },
   input: {
      display: 'none',
   },
   upload: {
      cursor: 'pointer',
      padding: '30px 20px',
      lineHeight: 'normal',
      border: `2px dashed #0000004d`,
      // color: theme.colors.inputBorder,
      fontSize: theme.typography.fontSize,
   },
   delete: {
      border: 'none',
      fontSize: '14px',
      cursor: 'pointer',
      padding: '5px 8px',
      lineHeight: 'normal',
      display: 'flex',
      alignItems: 'center',
      margin: 'auto'
   },
   image: {
      padding: 5,
      display: 'block',
      maxWidth: '100%',
      margin: '0 auto 10px',
      boxSizing: 'border-box',
      backgroundColor: 'white',
   },
   filename: {
      fontSize: 11,
      maxWidth: '100%',
      marginBottom: 10,
      overflow: 'hidden',
      textAlign: 'center',
      lineHeight: 'normal',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      // color: theme.colors.inputText,
      '-webkit-box-orient': 'vertical',
   },
   errorText: {
      color: 'red'
   },
   cropContainer: {
      position: 'relative',
      width: '100%',
      height: 400,
      backgroundColor: '#333',
   },
   cropButton: {
      marginTop: theme.spacing(2),
   },
   dialogContent: {
      padding: theme.spacing(4),
   },
   closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      cursor: 'pointer',
    },
}))

const ImageUpload = (props) => {
   console.log("🚀 ~ ImageUpload ~ props:", props)
   const { name, value, setFieldValue, placeholder = 'image' } = props;
   const classes = useStyles();
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState('');
   const [openCrop, setOpenCrop] = useState(false);
   const [selectedImage, setSelectedImage] = useState(null);
   const [crop, setCrop] = useState({ x: 0, y: 0 });
   const [zoom, setZoom] = useState(1);
   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

   const handleImageChange = async (event) => {
      setIsLoading(true);

      
      const imageFile = event.target.files[0];
      if (imageFile) {
         const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'];
         if (!allowedTypes.includes(imageFile.type)) {
            setError('Invalid file type. Only PNG, JPG, JPEG, and SVG are allowed.');
            setIsLoading(false);
            return;
         }


         try {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);
            reader.onload = () => {
               setOpenCrop(true);
               setSelectedImage(reader.result);
            };
         } catch (error) {
            console.log(error);
         }
      }
      setIsLoading(false);
   };

   const onCropComplete = useCallback((_, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
   }, []);

   const handleApplyCrop = async () => {
      try {
         const croppedImageBlob = await getCroppedImg(selectedImage, croppedAreaPixels);
         const croppedImageUrl = URL.createObjectURL(croppedImageBlob);

         setFieldValue(name, {
            name: `cropped-${Date.now()}`,
            type: croppedImageBlob.type,
            size: croppedImageBlob.size,
            data: croppedImageUrl,
            dataFile: croppedImageBlob,
         });

         setOpenCrop(false);
      } catch (e) {
         console.error(e);
      }
   };

   // Function to close the cropping dialog
   const handleCloseCrop = () => {
      setOpenCrop(false);
      setSelectedImage(null); // Reset selected image when closing
   };

   return (
      <div className={classes.root}>
         {!value ? (
            <div>
               <input
                  accept='image/*'
                  className={classes.input}
                  id={name}
                  type='file'
                  onChange={handleImageChange}
               />
               <label htmlFor={name}>
                  {isLoading ? (
                     <CircularProgress />
                  ) : (
                     <div className={classes.upload}>+ Upload {placeholder}</div>
                  )}
               </label>
               {error && <div className={classes.errorText}>{error}</div>}
            </div>
         ) : (
            <div>
               <img src={value.data || value} className={classes.image} alt='Uploaded file' />
               <button
                  className={classes.delete}
                  onClick={() => setFieldValue(name, '')}
               >
                  <CloseSharp /> Delete {placeholder}
               </button>
            </div>
         )}

         {/* Cropper Modal */}
         <Dialog open={openCrop} onClose={handleCloseCrop} maxWidth="md" fullWidth>
            <div className={classes.dialogContent}>
               <div className={classes.closeButton} onClick={handleCloseCrop}>
                  <CloseOutlined />
               </div>
               <div className={classes.cropContainer}>
                  <Cropper
                     image={selectedImage}
                     crop={crop}
                     zoom={zoom}
                     aspect={4 / 3}
                     onCropChange={setCrop}
                     onCropComplete={onCropComplete}
                     onZoomChange={setZoom}
                  />
               </div>
               <div className={classes.controls}>
                  <Slider
                     value={zoom}
                     min={1}
                     max={3}
                     step={0.1}
                     onChange={(e, zoom) => setZoom(zoom)}
                  />
                  <Button
                     onClick={handleApplyCrop}
                     color="primary"
                     variant="contained"
                     className={classes.cropButton}
                  >
                     Apply Crop
                  </Button>
               </div>
            </div>
         </Dialog>
      </div>
   );
};

ImageUpload.propTypes = {
   name: PropTypes.string.isRequired,
   value: PropTypes.any,
   placeholder: PropTypes.node,
   setFieldValue: PropTypes.func.isRequired,
};

ImageUpload.propTypes = {
   name: PropTypes.string.isRequired,
   value: PropTypes.any,
   placeholder: PropTypes.node,
   setFieldValue: PropTypes.func.isRequired,
}

export default ImageUpload
