import React, { useState, useCallback, useRef } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { DeleteSharp, CloseOutlined } from '@material-ui/icons';
import imageCompression from 'browser-image-compression'
import Cropper from 'react-easy-crop';
import { Button, Dialog, Slider } from '@material-ui/core';
import getCroppedImg from '../utils/imageCrop'; // Import the cropImage utility

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: -10,
    },
    item: {
      maxWidth: '50%',
      minWidth: '50%',
      padding: 10,
      boxSizing: 'border-box',
      [theme.breakpoints.up('md')]: {
        maxWidth: props => `${100 / props.columns}%`,
        minWidth: props => `${100 / props.columns}%`,
      },
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f5f5f5',
      padding: 15,
      height: '33vw',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        height: '20vw',
      },
      [theme.breakpoints.up('lg')]: {
        height: '12vw',
        maxHeight: 300,
      },
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    delete: {
      position: 'absolute',
      top: 0,
      right: 0,
      lineHeight: 'normal',
      fontSize: 16,
      padding: 5,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: '#00000021',
      },
    },
    input: {
      display: 'none',
    },
    label: {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      flexDrection: 'column',
      justifyContent: 'center',
      color: '#332e19',
      // '&:hover': {
      //   backgroundColor: theme.colors.fill,
      // },
    },
    cropContainer: {
      position: 'relative',
      width: '100%',
      height: 400,
      backgroundColor: '#333',
    },
    cropButton: {
      marginTop: theme.spacing(2),
    },
    dialogContent: {
      padding: theme.spacing(4), // Add padding here
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      cursor: 'pointer',
    },
  }
})

const MultiImageUpload = (props) => {
  const {
    name,
    value = '',
    setValue,
    columns
  } = props
  const classes = useStyles({ columns })
  // Additional state for handling errors
  const [error, setError] = useState('');
  const [openCrop, setOpenCrop] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'];
      if (!allowedTypes.includes(file.type)) {
        setError('Unsupported file type. Only PNG, JPG, JPEG, and SVG are allowed.');
        return;
      }
      setError(''); // Clear any existing errors
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setOpenCrop(true);
        setSelectedImage(reader.result);
      };
    }
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleApplyCrop = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(selectedImage, croppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob);

      // Update your value state to include the new cropped image
      const updatedValue = [
        ...value,
        {
          name: `cropped-${Date.now()}`, // Generate a unique name for the file
          type: croppedImageBlob.type,
          size: croppedImageBlob.size,
          data: croppedImageUrl, // Use the generated URL for display
          dataFile: croppedImageBlob // Store the Blob for further processing or upload
        }
      ];

      setValue(name, updatedValue);
      setOpenCrop(false);
      resetFileInput();
    } catch (e) {
      console.error(e);
    }
  };

  // Function to close the cropping dialog
  const handleCloseCrop = () => {
    setOpenCrop(false);
    setSelectedImage(null); // Reset selected image when closing
    resetFileInput(); // Reset the file input
  };

  const fileInputRef = useRef();



  return <div className={classes.root}>
    {value &&
      value.map((item, index) =>
        <div className={classes.item} key={index}>
          <div className={classes.imageWrapper}>
            <img
              src={item.data || item}
              className={classes.image}
              alt={`Uploaded file ${index}`}
            />
            <div
              className={classes.delete}
              onClick={() => setValue(
                name,
                value.filter((_, subIndex) => subIndex !== index),
              )}
            ><DeleteSharp /></div>
          </div>
        </div>
      )
    }
    <div className={classes.item}>
      <div className={classes.imageWrapper}>
        {/* <input
          id={name}
          multiple
          type='file'
          accept='image/*'
          className={classes.input}
          onChange={e => {
            let oldValue = value || []
            Object.values(e.target.files).forEach(async (value) => {
              const imageFile = value;
              const options = {
                maxSizeMB: 1,
                preserveExif: true,
                useWebWorker: true,
                alwaysKeepResolution: true,
              }
              try {
                const compressedFile = await imageCompression(imageFile, options);
                const fileReader = new FileReader()
                const { name: fileName, type: fileType } = imageFile
                fileReader.readAsDataURL(compressedFile)
                fileReader.onload = () => {
                  const data = fileReader.result
                  setValue(
                    name,
                    [...oldValue, {
                      name: fileName,
                      type: fileType,
                      data,
                      size: compressedFile.size,
                      dataFile: compressedFile,
                    }]
                  )
                  oldValue = [...oldValue, {
                    data,
                    name: fileName,
                    type: fileType,
                    size: compressedFile.size,
                    dataFile: compressedFile,
                  }]
                }
              } catch (error) {
                console.log(error);
              }
            })
          }}
        /> */}
        <input
          ref={fileInputRef}
          id={name}
          multiple
          type="file"
          accept="image/*"
          className={classes.input}
          onChange={handleImageChange}
        />
        <label htmlFor={name} className={classNames(classes.imageWrapper, classes.label)}>
          Add more
        </label>
        {/* Cropper Modal */}
        <Dialog open={openCrop} onClose={() => setOpenCrop(false)} maxWidth="md" fullWidth>
          <div className={classes.closeButton} onClick={handleCloseCrop}>
            <CloseOutlined />
          </div>
          <div className={classes.dialogContent}>

            <div className={classes.cropContainer}>
              <Cropper
                image={selectedImage}
                crop={crop}
                zoom={zoom}
                aspect={6 / 4.2}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className={classes.controls}>
              <Slider value={zoom} min={1} max={3} step={0.1} onChange={(e, zoom) => setZoom(zoom)} />
              <Button onClick={handleApplyCrop} color="primary" variant="contained" className={classes.cropButton}>
                Apply Crop
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  </div>
}

MultiImageUpload.defaultProps = {
  columns: 2,
}

export default MultiImageUpload
